import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Navbar from '../../components/Header';
import Footer from '../../components/Footer';

const industriesData = [
  {
    title: "Administrative",
    description:
      "Just like you need reliable staff to fill your administrative, clerical, and office needs – you need a reliable office temp agency that can deliver. That’s us.",
    positions: [
      "Receptionist",
      "Administrative Assistant",
      "Office Assistant",
      "Office Manager",
      "Accounts Payable",
      "Collection Clerk",
      "Bookkeepers",
      "And More"
    ],
    image: require("../../assets/i_administrative.png") 
  },
  {
    title: "Construction",
    description:
      "We have a proven track record of filling job site workforce needs faster with quality and reliable construction workers. We’re your dependable source for all your construction labour staffing needs.",
    positions: [
      "Carpenter",
      "Journeyman Carpenter",
      "Roofer",
      "Painter",
      "Drywall Installers",
      "Framer",
      "General Laborer",
      "Machinists",
      "And More"
    ],
    image: require("../../assets/i_construction.png")
  },
  {
    title: "Customer Service",
    description:
      "When it comes to hiring for customer service roles, we’re a leader in the industry, providing qualified candidates for your customer service needs.",
    positions: [
      "Call Center Representative",
      "Customer Service Specialist",
      "Client Services Coordinator",
      "Technical Support",
      "Telemarketer",
      "And More"
    ],
    image: require("../../assets/i_customer-service.png") 
  },
  {
    title: "Manufacturing",
    description:
      "Our staffing agency delivers experienced workers for your manufacturing business, ensuring a productive and skilled workforce.",
    positions: [
      "Machine Operator",
      "Assembly Line Worker",
      "Quality Control Inspector",
      "Production Supervisor",
      "Fabricator",
      "Forklift Operator",
      "And More"
    ],
    image: require("../../assets/i_manufacturing.png") 
  },
  {
    title: "Skilled Trade",
    description:
      "We connect skilled tradespeople with businesses that need quality workers who can get the job done right.",
    positions: [
      "Electrician",
      "Plumber",
      "Welder",
      "HVAC Technician",
      "Heavy Equipment Operator",
      "Mechanic",
      "And More"
    ],
    image: require("../../assets/i_skilled-trade.png") 
  },
  {
    title: "Warehouse",
    description:
      "We provide staffing solutions for all your warehouse and distribution needs, from loading and unloading to inventory management.",
    positions: [
      "Warehouse Associate",
      "Shipping and Receiving Clerk",
      "Inventory Specialist",
      "Forklift Operator",
      "Order Picker",
      "Logistics Coordinator",
      "And More"
    ],
    image: require("../../assets/i_warehouse.png")
  }
];

const Industries = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 720);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 720);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      maxWidth: "1400px",
      margin: "0 auto",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      margin: "40px 0"
    },
    industryContent: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      marginBottom: "40px",
      borderBottom: "1px solid #ccc",
      paddingBottom: "20px"
    },
    leftColumn: {
      padding: "20px",
      textAlign: "left",
      maxWidth: "600px"
    },
    title: {
      fontSize: "28px",
      fontWeight: 700,
      marginBottom: "20px"
    },
    description: {
      fontSize: "16px",
      lineHeight: "1.5",
      marginBottom: "20px",
      color: "#333"
    },
    positionsTitle: {
      fontSize: "20px",
      fontWeight: 600,
      marginTop: "20px",
      marginBottom: "10px"
    },
    positionItem: {
      fontSize: "16px",
      marginBottom: "8px",
      listStyleType: "none",
      position: "relative",
      paddingLeft: "20px"
    },
    bulletIcon: {
      content: "'⮞'",
      position: "absolute",
      left: 0,
      color: "#888"
    },
    image: {
      maxWidth: "80%",
      height: "auto",
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0,0,0,0.1)"
    }
  };

  return (
    <div style={{ height: '100vh' }} className="d-flex flex-column min-vh-100">
      <Navbar />
      <div style={styles.container}>
        <div style={styles.content}>
          {industriesData.map((industry, index) => (
            <div key={index} style={styles.industryContent}>
              <Col className="d-flex flex-column align-items-center">
                <h2 style={styles.title}>{industry.title}</h2>
                <img src={industry.image} alt={industry.title} style={styles.image} />
              </Col>

              <Col style={styles.leftColumn}>
                <p style={styles.description}>{industry.description}</p>
                <h5 style={styles.positionsTitle}>Positions We Fill</h5>
                <ul>
                  {industry.positions.map((position, index) => (
                    <li key={index} style={styles.positionItem}>
                      <span style={styles.bulletIcon}></span>
                      {position}
                    </li>
                  ))}
                </ul>
              </Col>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Industries;
