import { useState, useEffect } from "react";
import AdminHeader from "../../components/admin/AdminHeader"
import EmployerCard from "../../components/admin/EmployerCard"
import EmployeeCard from "../../components/admin/EmployeeCard"
import SideNav from "../../components/admin/SideNav";
import axios from "axios";

function Admin() {
    const [activeEmployer, setActiveEmployer] = useState(null);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [employerData, setEmployerData] = useState([]);
    const [tabs, setTabs] = useState({
        employerTab: true,
        employeeTab: true,
        employedTab: true,
    });

    async function getEmployers() {
        var res = await axios.get('/api/employers/get');
        const { data } = res;
        setEmployerData(data.data);
    };


    let employers = employerData;
    let employees = [];
    let employed = [];



    useEffect(() => {
        getEmployers()
    }, []);
    const handleTabs = (tab) =>{
        setTabs({...tabs, ...tab});
        console.log(tabs);

    }
    const handleSelectedEmployees = (id) => {
        if (selectedEmployees.includes(id)) {
            setSelectedEmployees(selectedEmployees.filter(employeeId => employeeId !== id));
        } else {
            setSelectedEmployees([...selectedEmployees, id]);
        }
    };

    const handleActive = (id) => {
        if (activeEmployer === id) {
            setActiveEmployer(null);
            return;
        }
        setActiveEmployer(id);
    };

    return (
        <>
            <div style={{ height: '100%' }}>
                <AdminHeader handleTabs={handleTabs} tabs={tabs}/>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-lg-2">
                            <SideNav />
                        </div>
                        <div className="col-lg-10 ps-lg-5 mt-5">
                            <div className="row d-flex h-75">
                                {tabs.employerTab ?
                                    <div className="col-lg-4 mb-5 mb-lg-0 mb-md-0">
                                        <h4 className="fs-3">Employers</h4>
                                        <div className="card h-100" >
                                            <div className="card-header">
                                                List of Employers
                                            </div>
                                            <div className="card-body overflow-auto" data-spy="scroll" data-bs-smooth-scroll="true" style={{ height: '30vh' }}>
                                                <ul className="list-group list-group-flush">
                                                    {
                                                        employers.map((employer) => { return <EmployerCard active={activeEmployer === employer._id} handleActive={() => handleActive(employer._id)} name={employer.first_name + " " + employer.last_name} key={employer._id} /> })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                                {tabs.employeeTab ?
                                    <div className="col-lg-4 mb-5 mb-lg-0 mb-md-0">
                                        <h4 className="fs-3">Employees</h4>
                                        <div className="card h-100 ">
                                            <div className="card-header">
                                                List of employable users
                                            </div>
                                            <div className="card-body overflow-auto" data-spy="scroll" data-bs-smooth-scroll="true" style={{ height: '30vh' }}>
                                                <ul className="list-group list-group-flush">
                                                    {
                                                        employees.map((employer) => { return <EmployerCard active={activeEmployer === employer._id} handleActive={() => handleActive(employer._id)} name={employer.first_name + " " + employer.last_name} key={employer._id} /> })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="card-footer text-body-secondary">
                                                button or something
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                                {tabs.employedTab ?
                                    <div className={`col-lg-4 mb-5 mb-lg-0 mb-md-0`}>
                                        <h4 className="fs-3">Employed</h4>
                                        <div className="card h-100">
                                            <div className="card-header">
                                                List of employed users
                                            </div>
                                            <div className="card-body" data-spy="scroll overflow-auto" data-bs-smooth-scroll="true" style={{ height: '30vh' }}>
                                                <ul className="list-group list-group-flush">
                                                    {
                                                        employed.map((employer) => { return <EmployerCard active={activeEmployer === employer._id} handleActive={() => handleActive(employer._id)} name={employer.first_name + " " + employer.last_name} key={employer._id} /> })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="card-footer text-body-secondary">
                                                button or something
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin