import Header from "../../components/admin/Header"
import SideNav from "../../components/admin/SideNav"
function UserList() {
  return (
    <>
    <div  style={{height: '90vh'}}>
                <Header />
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-lg-2 d-none d-lg-block d-md-block">
                            <SideNav />
                        </div>
                    </div>
                </div>
            </div>

    </>
  )
}

export default UserList