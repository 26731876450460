import "../../App.css";
import Header from '../../components/Header';
import Footer from "../../components/Footer"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmployerBanner from '../../assets/Banner.png'

import EmployerBanner2 from '../../assets/EmployerBanner2.png'
const Employers = () => {

  return (
    <>
      <Header />
      <div className="container-fluid   d-lg-block mb-lg-5 py-5 text-center text-white" style={{ background: '#105DBC', backgroundImage: `url(${EmployerBanner2})`, backgroundSize: 'cover', backgroundPosition: 'center 100%' }}>

        <div className="container position-relative">
          <h1 className="text-center mb-4">Employers</h1>
          <div>
            <p className="text-center fs-5">
              We approach gathering information about your business with meticulous care. This involves learning about your culture, operations,
              and the processes that drive your success. We also believe in investing the necessary time to get to know the talent we screen,
              ensuring that we bring you only the best candidates. We understand that every hour without the right
              person in a vacant role could be costing your business money.
              That's why delivering intelligent matches based on a deep understanding of the available talent in the market is our specialty.
            </p>
          </div>
        </div>

        <div>

        </div>
      </div>
      <div className="container mt-5 pt-5 mb-5">
        <div><h1 className="fw-light mb-5">FAQ</h1></div>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-4">Employer Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What exactly is an employment service?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              As an employment service, PrairieJobs is the gateway to qualified applicants who’ve already been screened, processed and are available for work.
              We do the work for you — the weeding out, background checks, drug screens and reference checks. Our Staffing Managers also take time to conduct a thorough and in-depth interview with every applicant, ensuring a successful placement for both the applicant and the employer.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What do PrairieJob’s fees include?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Our fees include employees’ compensation, government taxes, workers’ compensation, and general processing fees.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Does PrairieJobs offer any guarantees?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We offer a four-hour guarantee on every placement. If, within four hours, it’s apparent that an employee is not a good fit for any reason, they can be sent back without any charges to you, the employer.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What if I want to hire a PrairieJobs employee?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            When partnering with PrairieJobs, you’ll be asked to agree to a temp-to-hire contract. Once that agreement has been met, PraireJobs employees are eligible to be hired for no additional fees. Employees can be hired before the agreement has been met for an additional fee.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What makes PraireJobs’ employees better than the rest?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            The coaching, advice and genuine encouragement given to each employee by our Staffing Managers makes our employees stand out! Selectemp recognizes that, if our employees are successful, then our clients will be successful in turn. In the end, that makes Selectemp successful too!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What markets does PrairieJobs serve?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            PrairieJobs serves Alberta, Saskatchewan, and Manitoba. Visit our Contact page for full details.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What if I need to hire people outside of the markets PrairieJobs serves?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            PrairieJobs can be your staffing partner in any market. Just give us a call, and we’ll find employees in any market throughout Canada. You make one call, talk to one person and receive one rate.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Are PrairieJobs employees drug-free?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            PrairieJobs will drug test employees at your request, typically within 24 hours of the start of the assignment, to ensure the most reliable results.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Will I be able to contact PraireJobs in an emergency?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Absolutely! We always have a representative on-call to handle any emergency — 24 hours a day, 365 days a year.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">How does PrairieJobs ensure their employees are qualified for placement with my company?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            All applicants undergo an in-depth interview with our experienced Staffing Managers. We also verify employment background and administer written tests to verify skill sets.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Does PrairieJobs have the capability to staff for both small and large projects?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Absolutely! Our top-of-the-line industry software enables us to contact many employees in a very short time. At a moment’s notice, PrairieJobs’ large pool of employees is available to go to work promptly. These projects could require one employee, 20 employees, or many more.
            </Typography>
          </AccordionDetails>
        </Accordion>
        </AccordionDetails>
        </Accordion>
      </div>

      <Footer/>
    </>
  );
};

export default Employers;
