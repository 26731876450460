import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Employees from './pages/static/Employees';
import Admin from './pages/admin/Admin';
import TimeCard from './pages/admin/TimeCard';
import UserList from './pages/admin/UserList';
import Employee from './pages/Employee';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Employer from './pages/Employer';
import Contact from './pages/static/Contact';

import { Auth0Provider } from '@auth0/auth0-react';
import { useContext, createContext, useState, useEffect } from "react";
import CreateUser from "./pages/CreateUser";
import About from "./pages/static/About";
import ResetPassword from "./pages/ResetPassword";
import Employers from "./pages/static/Employers";
import Industries from "./pages/static/Industries";

export const ContextProvider = createContext();
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(user);
    }
  }, []);

  return (
    <BrowserRouter>
      <ContextProvider.Provider value={{ user, setUser }}>
        <Auth0Provider
          domain="dev-94097o85.us.auth0.com"
          clientId="94097o85"
          redirectUri={window.location.origin}
        >
          <Routes>
            <Route path='/' element={<Employees />} />
            <Route path='/employers' element={<Employers />} />
            <Route path='/industries' element={<Industries />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/login' element={<Login />} />

            <Route path='/employee' element={<Employee />} />
            <Route path='/employer' element={<Employer />} />
            <Route path='/admin' element={<Admin />} />

            <Route path='/time' element={<TimeCard />} />
            <Route path='/user-list' element={<UserList />} />

            <Route path="/reset-password/:id" element={<ResetPassword />} />

            <Route path='*' element={<NotFound />} />
          </Routes>
        </Auth0Provider>
      </ContextProvider.Provider>
    </BrowserRouter>
  );
}

export default App;
