function EmployerCard({name,  handleActive, active}) {

    return (
        <li type="button" className={`list-group-item  d-flex justify-content-between align-items-start ${active  ? 'active rounded' : ''}`}>
        <div class="ms-2 me-auto">
            <div class="fw-bold">{name}</div>
            company maybe etc
        </div>
        <span class=""><input class="form-check-input me-1 ms-lg-5" type="checkbox" value="" id="flexCheckDefault" checked={active} onChange={()=>{handleActive();}} /></span>
    </li>
    )
}

export default EmployerCard
