import Header from "../../components/Header"
import Footer from "../../components/Footer"
import JumbotronImage from '../../assets/jumbotron/Jumbotron.png'
import RegisterIcon from '../../assets/jumbotron/RegisterIcon.png'
import CreateIcon from '../../assets/jumbotron/CreateIcon.png'
import UploadIcon from '../../assets/jumbotron/UploadIcon.png'
import SearchIcon from '../../assets/jumbotron/SearchIcon.png'
import Construction from "../../assets/Construction.png";
import Administration from "../../assets/Administration.png";
import Manufacturing from "../../assets/Manufacturing.png";
import MobileBanner from "../../assets/Banner.png";
import HomeBanner from "../../assets/HomeBanner.png";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Employees() {
  return (
    <>
      <style>
        {`
          a.nav-link {
            color: black;
          }
           a.nav-link.active {
            font-weight: bold;
          } 
        .box_3 {
              display: block;
              height: 120px;
              position: absolute; /* Keep it absolute */
              left: 0; /* Aligns it to the left */
              right: 0; /* Aligns it to the right, making it full width */
              width: 100%; /* Full width of the jumbotron */
              transform: translateY(-20px); /* Translate downwards, adjust as needed */
              z-index: 1; /* Ensures it's above other elements, adjust if necessary */
              box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
              background: #FFFFFF;
        }
          @media (min-width: 992px){
              .custom-spacing {
              margin-top: 200px; /* Adjust the value as needed */
              }
          }
        `}
      </style>
      <Header />

      {/**DESKTOP VIEW */}
      <div class=" d-lg-block d-none " style={{ background: "linear-gradient(to bottom, rgba(17, 92, 189, 0.14) 0%, rgba(255, 255, 255, 0) 100%)", maxHeight: '500px', overflow: 'hidden', height: "50vh" }}>
        <div class="jumbotron"
          style={{
            backgroundImage: `url(${JumbotronImage})`, backgroundSize: 'auto', backgroundPosition: 'center 10%', backgroundRepeat: 'no-repeat', maxWidth: '1900px', margin: '0 auto', maxHeight: '500px', overflow: 'hidden', height: "50vh"
          }}>
          <div className="container h-100 d-flex align-items-center ">
            <div className="1 row w-50 fs-1"> {/* Text-center to center text */}
              <h1 style={{ fontWeight: "700" }}>
                <div>Discover your Next Opportunity</div>
                <div>Connect Apply Succeed</div>
              </h1>
            </div>
          </div>
        </div>
        <div className="container box_3 d-flex justify-content-center align-items-center ">
          <div className="row w-100">
            <div className="col-lg-3 d-flex justify-content-center">
              <div className=" row justify-content-center">
                <div className="col-lg-12 d-flex justify-content-center">
                  <img src={RegisterIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  Register
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex justify-content-center">
              <div className=" row justify-content-center">
                <div className="col-lg-12 d-flex justify-content-center">
                  <img src={CreateIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  Send your Resume
                </div>
              </div>

            </div>
            <div className="col-lg-3 d-flex justify-content-center">
              <div className=" row justify-content-center">
                <div className="col-lg-12 d-flex justify-content-center">
                  <img src={UploadIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  Join the System
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-flex justify-content-center">
              <div className=" row justify-content-center">
                <div className="col-lg-12 d-flex justify-content-center">
                  <img src={SearchIcon} width="58" height="58" class="img-fluid" alt="Register Icon" />
                </div>
                <div className="col-lg-12 d-flex justify-content-center">
                  Get Work
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>


      {/**SMALL SCREEN VIEW  WILL BE DONE LATER*/}

      <div className="container d-lg-none d-flex justify-content-center "
        style={{
          backgroundImage: `url(${MobileBanner})`, backgroundSize: 'auto', backgroundPosition: '20% 50%', backgroundRepeat: 'no-repeat', maxWidth: '1900px', margin: '0 auto', maxHeight: '500px', overflow: 'hidden', height: "50vh"
        }}>
        <div className="h-100 d-flex align-items-center ">
          <div className="1 row text-center text-white fs-1"> {/* Text-center to center text */}
            <h1 style={{ fontWeight: "700" }}>
              <div>Discover your Next Opportunity</div>
              <div>Connect Apply Succeed</div>
            </h1>
          </div>
        </div>
      </div>

      <div className="container custom-spacing ">
        <h1 className="fs-1 text-lg-start text-center mt-lg-0 mt-5">Get the Job that Best Fits You</h1>
        <div className="row mt-5 mb-5 pb-5 gx-5">
          <div className="col-lg-4 d-flex justify-content-lg-start justify-content-center px-lg-0">
            <div class="card text-white" style={{ border: 'none' }}>
              <img src={Construction} class="card-img" alt="..." />
              <div class="card-img-overlay d-flex justify-content-center align-items-center">
                <h1 class="card-title">Construction</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-center px-lg-0">
            <div class="card text-white" style={{ border: 'none' }}>
              <img src={Administration} class="card-img" height="100%" alt="..." />
              <div class="card-img-overlay  d-flex justify-content-center align-items-center">
                <h1 class="card-title">Administration</h1>

              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center px-lg-0">
            <div class="card text-white" style={{ border: 'none' }}>
              <img src={Manufacturing} class="card-img" height="100%" alt="..." />
              <div class="card-img-overlay  d-flex justify-content-center align-items-center">
                <h1 class="card-title">Manufacturing</h1>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-5 p-5 " style={{ background: '#105DBC', backgroundImage: `url(${HomeBanner})`, backgroundPosition:' center 3%', backgroundSize: 'cover' , backgroundRepeat: 'no-repeat'}}>
        <div className="container">
          <div className="row text-white">
            <div className="col-lg-6 text-lg-start text-center">
              <h1 className="fw-bold mb-3">Search Jobs</h1>
              <p className="fw-semibold">You don’t have to navigate the job search alone with PrairieJobs as your guide. We’ve been helping job seekers in Western Canada find employment in light industrial, construction, and clerical roles since 2023. We will take the time to understand your talents so that you can easily and quickly land the best opportunity.</p>
            </div>
            <div className="col-lg-6 d-lg-flex align-items-center justify-content-end d-none fw-bold" >
              <a href="/contact" className="text-white" style={{textDecoration:'none'}}><h1>Contact Us ➤</h1></a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 pt-0 mb-5 pb-3">

      <div><h1 className="fw-light mb-5">FAQ</h1></div>
      <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-4">Employee Information</Typography>
          </AccordionSummary>
          <AccordionDetails>

        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">How do I apply for a job with PrairieJobs?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You may come into our office to apply and hopefully interview immediately after 
              completing your application, OR you can fill out this Application and email it to us.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Does PrairieJobs charge any fees to its employees?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No! PrairieJobs does not charge our employees any fees.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Are all PrairieJobs jobs temporary?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Most of our jobs are “temp-to-hire,” meaning they are intended to become regular, 
              ongoing positions after a certain period. PrairieJobs also hires for projects, which 
              tend to last for a specified duration.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">How should I dress when applying to the PrairieJobs office?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              All applicants should dress appropriately for the specific type of position that they want. 
              There are many occasions when an applicant will complete the interview with a Staffing Manager, 
              and then have the opportunity to either begin work immediately or have the opportunity to leave 
              our office and go for another interview with one of our clients.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">How should I dress when applying to the PrairieJobs office?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              All applicants should dress appropriately for the specific type of position that they want. 
              There are many occasions when an applicant will complete the interview with a Staffing Manager, 
              and then have the opportunity to either begin work immediately or have the opportunity to leave 
              our office and go for another interview with one of our clients.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">How much experience do I need to get a job?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Necessary experience depends upon the position you are applying for. Not all of our positions 
              require a specific skill set. Some offer opportunities for on-the-job training, while others 
              require verifiable and practical work experience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What kinds of jobs does PrairieJobs have available?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              PrairieJobs places employees in various industries, such as production, construction labor, 
              industrial, manufacturing, administration, clerical, technical, and more. 
              See our full List of currently available jobs.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Do I need to get a pre-employment drug screen?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              While PrairieJobs is a drug-free workplace based on federal guidelines, a pre-placement drug 
              screen may or may not be administered following certain employer guidelines as directed by 
              the client at the time of placement.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">What are PrairieJobs hours?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              PrairieJobs office hours are 8:00 AM – 5:00 PM, Monday through Friday. A PrairieJobs 
              representative is available 24 hours a day for emergency contact. PrairieJobs also has a 
              24-hour answering service available to take each phone call personally. All messages are 
              delivered to office staff to be handled as quickly as needed. See our Contact page to contact 
              your local office.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="py-2">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography className="fw-bold fs-5">Once I am placed on assignment, how and when will I be paid?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              To eliminate payday hassles for our employees, PrairieJobs offers two convenient ways to receive 
              their paychecks. We offer direct deposit into your personal bank account or onto a Pay Card 
              that we will issue. Payroll is run on a weekly basis, and you will get paid the week following.
            </Typography>
          </AccordionDetails>
        </Accordion>
        </AccordionDetails>
        </Accordion>
        </div>

      <Footer/>

    </>
  )
}

export default Employees