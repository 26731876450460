import React from 'react';
import Logo from "../assets/image.png";

const Footer = () => {
    return (
        <div className="container-fluid mt-auto" style={{ background: '#262626' }}>
            <div className="container text-white py-4">
                <footer className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <div className="d-flex align-items-center mb-3 mb-sm-0">
                        <a href="/" className="text-decoration-none">
                            <img src={Logo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top me-2" />
                        </a>
                        <span>© 2024 PrairieJobs</span>
                    </div>

                    <ul className="nav list-unstyled d-flex flex-wrap justify-content-center">
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/">Employees</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/employers">Employers</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/industries">Industries</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/about">About</a></li>
                        <li className="ms-3"><a className="text-decoration-none text-white" href="/contact">Contact</a></li>
                    </ul>
                </footer>
                
                <div className="text-center mt-4">
                    <p className="mb-1">4270 E Buckingham Dr., Regina, SK, S4V 3Y9</p>
                    <p className="mb-1">Phone: <a href="tel:+13065650789" className="text-white"> (306) 565-0789</a></p>
                    <p>Email: <a href="mailto:contact@PrairieJobs.ca" className="text-white">contact@PrairieJobs.ca</a></p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
