import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { useState, Fragment, useEffect } from 'react';
import Header from '../components/admin/Header';
import Dropdown from '../components/employer/Dropdown';
import axios from 'axios';

function createData(name, status, numEmployees, history) {
    return {
        name,
        status,
        numEmployees,
        history: [
            ...history

        ],
    };
}

function formatDailyHours(dailyHours) {
    const days = {
      Sun: 'sunday',
      Mon: 'monday',
      Tue: 'tuesday',
      Wed: 'wednesday',
      Thu: 'thursday',
      Fri: 'friday',
      Sat: 'saturday',
    };
    
    const formatted = {};
    dailyHours.forEach((entry) => {
      const timeRange = entry.start_time && entry.end_time ? `${entry.start_time} - ${entry.end_time}` : 'OFF';
      formatted[days[entry.day]] = timeRange;
    });
    
    return formatted;
  }

function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.numEmployees}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>



                            {row.history.map((historyRow) => (
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2} className='fw-bold'>{historyRow.fullname}</TableCell>
                                            <TableCell colSpan={1}><Chip label="Pending" color="warning" variant="outlined" /></TableCell>
                                            <TableCell colSpan={2} className='fw-bold'>Week of Aug 10</TableCell>
                                            <TableCell colSpan={2} className='fw-bold'>
                                              <Dropdown />
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Sun, Aug 10</TableCell>
                                            <TableCell>Mon, Aug 10</TableCell>
                                            <TableCell>Tue, Aug 10</TableCell>
                                            <TableCell>Wed, Aug 10</TableCell>
                                            <TableCell>Thur, Aug 10</TableCell>
                                            <TableCell>Fri, Aug 10</TableCell>
                                            <TableCell>Sat, Aug 10</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={historyRow.id}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.sunday}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {historyRow.monday}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {historyRow.tuesday}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {historyRow.wednesday}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {historyRow.thursday}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {historyRow.friday}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {historyRow.saturday}
                                            </TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ))}


                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        status: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        numEmployees: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};






const rows = [
    createData('Alisson Brown', <Chip label="Ongoing" color="success" variant="outlined" />, 10, [{
        id: 1, // Optional, if needed for selection or other purposes
        fullname: 'John Doe',
        sunday: '8:00 AM - 4:00 PM',
        monday: '9:00 AM - 5:00 PM',
        tuesday: '8:00 AM - 4:00 PM',
        wednesday: '9:00 AM - 5:00 PM',
        thursday: '8:00 AM - 4:00 PM',
        friday: '8:00 AM - 4:00 PM',
        saturday: 'OFF',
    },
    {
        id: 1, // Optional, if needed for selection or other purposes
        fullname: 'John idunno',
        sunday: '8:00 AM - 4:00 PM',
        monday: '9:00 AM - 5:00 PM',
        tuesday: '8:00 AM - 4:00 PM',
        wednesday: '9:00 AM - 5:00 PM',
        thursday: '8:00 AM - 4:00 PM',
        friday: '8:00 AM - 4:00 PM',
        saturday: 'OFF',
    }]),
    createData('Ellie Gloden', <Chip label="Ongoing" color="success" variant="outlined" />, 10, [{
        id: 1, // Optional, if needed for selection or other purposes
        fullname: 'John Doe',
        sunday: '8:00 AM - 4:00 PM',
        monday: '9:00 AM - 5:00 PM',
        tuesday: '8:00 AM - 4:00 PM',
        wednesday: '9:00 AM - 5:00 PM',
        thursday: '8:00 AM - 4:00 PM',
        friday: '8:00 AM - 4:00 PM',
        saturday: 'OFF',
    }]),
    createData('Butcher Brown', <Chip label="Ongoing" color="success" variant="outlined" />, 10, [{
        id: 1, // Optional, if needed for selection or other purposes
        fullname: 'John Doe',
        sunday: '8:00 AM - 4:00 PM',
        monday: '9:00 AM - 5:00 PM',
        tuesday: '8:00 AM - 4:00 PM',
        wednesday: '9:00 AM - 5:00 PM',
        thursday: '8:00 AM - 4:00 PM',
        friday: '8:00 AM - 4:00 PM',
        saturday: 'OFF',
    }]),
    createData('Daniel Cliff', <Chip label="Ongoing" color="success" variant="outlined" />, 10, [{
        id: 1, // Optional, if needed for selection or other purposes
        fullname: 'John Doe',
        sunday: '8:00 AM - 4:00 PM',
        monday: '9:00 AM - 5:00 PM',
        tuesday: '8:00 AM - 4:00 PM',
        wednesday: '9:00 AM - 5:00 PM',
        thursday: '8:00 AM - 4:00 PM',
        friday: '8:00 AM - 4:00 PM',
        saturday: 'OFF',
    }]),
    createData('Gabe Tubman', <Chip label="Ongoing" color="success" variant="outlined" />, 10, [{
        id: 1, // Optional, if needed for selection or other purposes
        fullname: 'John Doe',
        sunday: '8:00 AM - 4:00 PM',
        monday: '9:00 AM - 5:00 PM',
        tuesday: '8:00 AM - 4:00 PM',
        wednesday: '9:00 AM - 5:00 PM',
        thursday: '8:00 AM - 4:00 PM',
        friday: '8:00 AM - 4:00 PM',
        saturday: 'OFF',
    }]),
];
async function getEmployerTimecards(){
    if (localStorage.getItem('user') != null) {
        const employer_id = JSON.parse(localStorage.getItem('user'))._id;
        const response = await axios.get(`/api/timecards/getEmployerTimecards/${employer_id}`);
        const {data} = response.data;
        console.log(data);
    }
  }
  getEmployerTimecards();

  
function Employer() {
    const [data, setData] = useState(null);
   
      

    return (
        <>
            <div style={{ height: '90vh' }}>
                <Header />
                <div className="container">
                    <div className="row mt-5 ">
                       
                            <div className="row mb-5">
                                <TableContainer component={Paper}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />

                                                <TableCell>Employer</TableCell>
                                                <TableCell align="right">
                                                    Status
                                                </TableCell>
                                                <TableCell align="right"># Employees</TableCell>


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <Row key={row.name} row={row} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Employer