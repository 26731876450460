import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Workers from "../../assets/About-Workers.png"
import Pipes from "../../assets/Pipes.png"
import Num1 from "../../assets/number icons/Num1.png"
import Num2 from "../../assets/number icons/Num2.png"
import Num3 from "../../assets/number icons/Num3.png"
import Num4 from "../../assets/number icons/Num4.png"
import Num5 from "../../assets/number icons/Num5.png"
import Num6 from "../../assets/number icons/Num6.png"
import Accountability from '../../assets/value icons/Accountability.png'
import Activelearning from '../../assets/value icons/Activelearning.png'
import Competition from '../../assets/value icons/Competition.png'
import Engaged from '../../assets/value icons/Engaged.png'
import Gratitude from '../../assets/value icons/Gratitude.png'
import Vulnerability from '../../assets/value icons/Vulnerability.png'
function About() {
  return (
    <>
      <style>
        {`
         @media (min-width: 992px) {
    .custom-width {
      width: 50%;
    }
         
        `}
      </style>
      <Header />

      <div className="container pt-5 mt-5 pb-5 mb-5 d-flex justify-content-center">
        <div className="row gx-5 pb-lg-5 mb-lg-5">
          <div className="col-lg-6 ">
            <div><p className="fs-5">Overview</p></div>
            <div>
              <h1 className="fs-1">What we believe in</h1>
            </div>
            <div>
              <p className="fs-3 mt-lg-5 pe-lg-5 ">
                We establish and build long-term, authentic relationships with our clients and our talent,
                empowering us to consistently deliver strong results based on collaboration and trust.
                These unique principles are the foundation of everything we believe in – driving success for talent and clients alike.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
            <img src={Workers} class="img-fluid" height="100%" alt="..." />
          </div>
        </div>
      </div>


      <div className="container-fluid mt-5 text-center text-white p-lg-5 " style={{ background: '#105DBC' }}>
        <div className="container custom-width p-5">
          <div>
            <h1>The Values that we live by</h1>
          </div>
          <div>
            <p className="fw-semibold mt-3 fs-6">
              PrairieJobs Employment Services operates under a system of principles that guides our approach to each engagement. It is part employee handbook,
              part manifesto—the corporate glue that holds it all together, helping us focus more on what matters—the customer. We own this—from start to finish.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5 pb-5" style={{background:'#f4f6f9'}}>
        <div className="container pt-5 pb-5">
          <div className=" d-flex">
            <div className="d-flex align-items-center pr-3">
            <img src={Accountability} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="w-100">
              <hr/>
              <h1>Accountability</h1>
              <p>Establish transparency through openness.</p>
            </div>
          </div>
          <div className=" d-flex">
            <div className="d-flex align-items-center pr-3">
            <img src={Vulnerability} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="w-100">
              <hr/>
              <h1>Vulnerability</h1>
              <p>Build connections through authenticity</p>
            </div>
          </div>
          <div className=" d-flex">
            <div className="d-flex align-items-center pr-3">
            <img src={Gratitude} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="w-100">
              <hr/>
              <h1>Gratitude</h1>
              <p>Appreciate life's experiences-- both the good and the bad</p>
            </div>
          </div>
          <div className=" d-flex">
            <div className="d-flex align-items-center pr-3">
            <img src={Competition} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="w-100">
              <hr/>
              <h1>Competition</h1>
              <p>Have a passion for winning</p>
            </div>
          </div>
          <div className=" d-flex">
            <div className="d-flex align-items-center pr-3">
            <img src={Activelearning} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="w-100">
              <hr/>
              <h1>Active Learning</h1>
              <p>Always be in pursuit to grow personally and professionally</p>
            </div>
          </div>
          <div className=" d-flex">
            <div className="d-flex align-items-center pr-3">
            <img src={Engaged} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="w-100">
              <hr/>
              <h1>Engaged</h1>
              <p>Be immersed in the hustle--work can be fun, fulfilling, and exciting</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 mt-5 pb-5 mb-5">
        <div className="row gx-5 pt-lg-5 mt-lg-5 mb-5 pb-5">
          <div className="col-lg-6">
            <div><p className="fs-5">Values</p></div>
            <div>
              <h1 className="fs-1">Our Vision</h1>
            </div>
            <div>
              <p className="fs-3 mt-5 pe-5">
              Our culture and values are the foundation for our success. We believe that if you leverage the environmental “pillars” in the right manner, 
              it will root each of us in the 
              way we conduct business (our “values”) – supporting the vision and decision-making process within our organization.
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
            <img src={Pipes} class="img-fluid" height="100%" alt="..." />
          </div>
        </div>
        <div className="row mt-5 pt-5">
          <div className="col-lg-4 d-flex justify-content-lg-start justify-content-center mb-3 mb-lg-0">
            <div>
            <img src={Num1} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="ms-4 w-50">
              <div><p className="fw-bold fs-4 mb-1">Communication</p></div>
              <div><p>Paint pictures to establish alignment</p></div>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-center mb-3 mb-lg-0">
            <div>
            <img src={Num2} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="ms-4 w-50">
              <div><p className="fw-bold fs-4 mb-1">Entrepreneurship</p></div>
              <div><p>Take responsibility for your outcomes</p></div>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center mb-3 mb-lg-0">
            <div>
            <img src={Num3} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="ms-4 w-50">
              <div><p className="fw-bold fs-4 mb-1">Make it happen</p></div>
              <div><p>Determination drives results</p></div>
            </div>
          </div>
        </div>

        <div className="row mt-lg-5 ">
          <div className="col-lg-4 d-flex justify-content-lg-start justify-content-center mb-1 mb-lg-0">
            <div>
            <img src={Num4} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="ms-4 w-50">
              <div><p className="fw-bold fs-4 mb-1">Teamwork</p></div>
              <div><p>Make each other better because iron sharpens iron</p></div>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-center mb-3 mb-lg-0">
            <div>
            <img src={Num5} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="ms-4 w-50">
              <div><p className="fw-bold fs-4 mb-1">Relationships</p></div>
              <div><p>Be a giver, not a taker</p></div>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center mb-3 mb-lg-0">
            <div>
            <img src={Num6} class="img-fluid" height="100%" alt="..." />
            </div>
            <div className="ms-4 w-50">
              <div><p className="fw-bold fs-4 mb-1">Integrity</p></div>
              <div><p>Choose courage over comfort</p></div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </>
  )
}

export default About